import React, { ComponentProps, FC, ReactNode, useState } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';
import { Check } from 'lucide-react';

import { cn } from '../lib/utils';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  UIButton,
  UILabel
} from '../ui';

type InputSelectDropdownOption = {
  value: string | number;
  content?: string | number;
  label: string;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  disabled?: boolean;
};

export type InputSelectDropdownProps = ComponentProps<typeof Popover> & {
  options: InputSelectDropdownOption[];
  label?: string;
  searchPlaceholder?: string;
  noSearchResultsText?: string;
  defaultSelected?: InputSelectDropdownOption;
  isHorizontal?: boolean;
  description?: string;
  className?: string;
  disabled?: boolean;
  dropdownAlignment?: 'start' | 'center' | 'end';
  dropdownSide?: 'top' | 'right' | 'bottom' | 'left';
  showCheckMarkOnSelect?: boolean;
  onOptionChange?: (value: string) => void;
};

const InputSelectDropdown: FC<InputSelectDropdownProps> = ({
  label,
  options,
  searchPlaceholder,
  noSearchResultsText,
  defaultSelected,
  isHorizontal,
  description,
  className,
  disabled,
  dropdownAlignment = 'start',
  dropdownSide = 'bottom',
  showCheckMarkOnSelect,
  onOptionChange
}) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    defaultSelected || { value: '', label: '' }
  );

  return (
    <div
      className={cn(
        'relative flex w-full',
        isHorizontal
          ? label
            ? 'items-top space-x-4'
            : 'items-top space-x-0'
          : 'flex-col',
        className
      )}
    >
      {label && (
        <UILabel
          htmlFor="select"
          className={cn(
            'block text-p-s font-medium text-base-foreground mb-2',
            isHorizontal ? 'pt-2' : ''
          )}
        >
          {label}
        </UILabel>
      )}
      <div className="w-full">
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <UIButton
              variant="outline"
              className="group h-10 justify-between bg-white p-s text-base-foreground font-normal w-full hover:bg-base-accent focus:bg-base-accent"
              disabled={disabled}
            >
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center">
                  {selectedOption.leftIcon && (
                    <div className="mr-2">{selectedOption.leftIcon}</div>
                  )}
                  {selectedOption.label}
                </div>
                <div>
                  {selectedOption.rightIcon ?? (
                    <FontAwesomeIcon
                      icon="fa-solid fa-chevron-down"
                      className={cn(
                        'text-base-foreground transition-transform group-data-[state=open]:rotate-180'
                      )}
                    />
                  )}
                </div>
              </div>
            </UIButton>
          </PopoverTrigger>
          <PopoverContent
            align={dropdownAlignment}
            side={dropdownSide}
            className="w-[--radix-popover-trigger-width] p-0 rounded-md p-s bg-white text-base-foreground font-normal z-1000"
          >
            <Command>
              {searchPlaceholder && (
                <CommandInput
                  className="p-s text-base-mutedForeground"
                  placeholder={searchPlaceholder}
                />
              )}
              <CommandList>
                {noSearchResultsText && (
                  <CommandEmpty>{noSearchResultsText}</CommandEmpty>
                )}
                <CommandGroup>
                  {options.map((option: InputSelectDropdownOption) => (
                    <CommandItem
                      key={option.value}
                      value={option.value as string}
                      onSelect={() => {
                        setSelectedOption(option);
                        setOpen(false);
                        if (onOptionChange)
                          onOptionChange(option.value as string);
                      }}
                      disabled={option.disabled}
                      className="hover:bg-base-accent rounded-md"
                    >
                      <div className="flex items-center justify-between w-full cursor-pointer">
                        <div className="flex items-center">
                          {option.leftIcon && (
                            <div className="mr-2">{option.leftIcon}</div>
                          )}
                          {option.label}
                        </div>
                        {option.rightIcon && <div>{option.rightIcon}</div>}
                        {showCheckMarkOnSelect && (
                          <Check
                            className={cn(
                              'ml-auto',
                              selectedOption.value === option.value
                                ? 'bg-base-foreground'
                                : 'opacity-0'
                            )}
                          />
                        )}
                      </div>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        {description && (
          <div className="text-p-s mt-2 text-base-mutedForeground">
            {description}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputSelectDropdown;
