import React, { useState } from 'react';

import PortalHeader from '../../components/PortalHeader/PortalHeader';
import RichText from '../../components/RichText/RichText';
import Switch from '../../components/Switch/Switch';

import { FontAwesomeIcon } from '@appcharge/shared-ui';
import Input from '../../components/Input/Input';
import ImageUpload from '../../components/ImageUpload/ImageUpload';
import AcSolidInput from '../../components/AcSolidInput/AcSolidInput';

const SettingsView = () => {
  return (
    <div>
      <PortalHeader
        title="Settings"
        primaryButtonText="Save"
        secondaryButtonText="Preview"
        onPrimaryButtonClick={() => { }}
        onSecondaryButtonClick={() => { }}
        secondaryButtonVariant={'outline'}
        secondaryButtonIcon={<FontAwesomeIcon icon="fa-regular fa-eye" />}
      />
      <div className="border border-base-sidebarBorder rounded-[20px] mx-7 my-3 p-9">
        <RichText
          title="Header"
          description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer"
          className="mb-11"
        />
        <div className="flex flex-col mb-9 w-[586px]">
          <div className="grid grid-cols-2 gap-9 mb-9">
            <Input label="Title" placeholder="Name" />
          </div>
          <div className="mb-9">
            <ImageUpload
              className="w-full"
              title="Upload Logo"
              description="Will be scaled down to 130 x 60px"
              buttonText="Upload"
            />
          </div>
          <div className="grid grid-cols-2 mb-9 gap-9">
            <AcSolidInput
              header="Background Color"
              name="backgroundColor"
              defaultValue={'#CB1010'}
              setValue={() => { }}
              onBlur={() => { }}
            />
            <AcSolidInput
              header="Text Color"
              name="textColor"
              defaultValue={'#CB1010'}
              setValue={() => { }}
              onBlur={() => { }}
            />
          </div>
          <div className="grid grid-cols-1 gap-y-9">
            <Switch
              label="Show Player Name"
              description="Lorem Ipsum is simply dummy text of the printing"
              textAlignment="right"
              size="lg"
            />
            <Switch
              label="Show Balance"
              description="Lorem Ipsum is simply dummy text of the printing"
              textAlignment="right"
              size="lg"
            />
            <Switch
              label="Show Login"
              description="Lorem Ipsum is simply dummy text of the printing"
              textAlignment="right"
              size="lg"
            />
            <Switch
              label="Show Translations"
              description="Lorem Ipsum is simply dummy text of the printing"
              textAlignment="right"
              size="lg"
            />
          </div>
        </div>
      </div>

      <div className="border border-base-sidebarBorder rounded-[20px] mx-7 my-3 p-9">
        <RichText
          title="Sidebar"
          description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer"
          className="mb-11"
        />
        <div className="grid grid-cols-3 gap-9 w-[897px]">
          <AcSolidInput
            header="Background Color"
            name="backgroundColor"
            defaultValue={'#CB1010'}
            setValue={() => { }}
            onBlur={() => { }}
          />
          <AcSolidInput
            header="Text Color"
            name="textColor"
            defaultValue={'#CB1010'}
            setValue={() => { }}
            onBlur={() => { }}
          />
          <AcSolidInput
            header="Text Hover Color"
            name="textColor"
            defaultValue={'#CB1010'}
            setValue={() => { }}
            onBlur={() => { }}
          />
        </div>
      </div>
      <div className="border border-base-sidebarBorder rounded-[20px] mx-7 my-3 p-9">
        <RichText
          title="Footer"
          description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer"
          className="mb-11"
        />
        <div className="grid grid-cols-2 mb-9 gap-9 w-[586px]">
          <AcSolidInput
            header="Background Color"
            name="backgroundColor"
            defaultValue={'#CB1010'}
            setValue={() => { }}
            onBlur={() => { }}
          />
          <AcSolidInput
            header="Text Color"
            name="textColor"
            defaultValue={'#CB1010'}
            setValue={() => { }}
            onBlur={() => { }}
          />
        </div>
        <div className="grid grid-cols-1 gap-9 w-[586px]">
          <Input label="Privacy Link" placeholder="htpp://" />
          <Input label="T&C Link" placeholder="htpp://" />
          <Input label="Support Link" placeholder="htpp://" />
        </div>
      </div>
    </div>
  );
};

export default SettingsView;
