import { useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { EBundlesViewModel } from '@appcharge/shared-ui';
import { Divider, Grid, Stack } from '@mui/material';
import useUsers from 'api/useUsers';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import {
  mapSpecialOffers,
  mapViewModelDisplayName
} from 'utils/mapSpecialOffers';

import { defaultTheme } from 'components/Theme/defaults';

import useImages from '../../../api/useImages';
import usePublisher from '../../../api/usePublisher';
import useTheme from '../../../api/useTheme';
import {
  BannerAspectRatio,
  EBalancesTheme,
  EBundlesInternalViewModel,
  ESectionViewModel,
  ESpecialOfferInternalViewModel
} from '../../../constants/enums';
import { uiActions } from '../../../store/uiSlice';
import { EToasterStatus, showToast } from '../../../utils/showToast';
import AcCard from '../../AcCard/AcCard';
import AcSelect from '../../AcSelect/AcSelect';
import ActionButton from '../../ActionButton/ActionButton';

import { BodyBlocks } from './BodyBlocks/BodyBlocks';

import '../../../style/forms.scss';
import '../../Theme/style.scss';

const mapBundleTypesBasedOnLayout: Record<EBundlesViewModel, any[]> = {
  [EBundlesViewModel.LINEAR]: [
    {
      content: 'Main Sub',
      key: EBundlesInternalViewModel.MAIN_SUB,
      value: EBundlesInternalViewModel.MAIN_SUB
    },
    {
      content: 'Baba',
      key: EBundlesInternalViewModel.BABA,
      value: EBundlesInternalViewModel.BABA
    },
    {
      content: 'PM - Jackpot Slots',
      key: EBundlesInternalViewModel.JACKPOT_SLOTS,
      value: EBundlesInternalViewModel.JACKPOT_SLOTS
    },
    {
      content: 'PM - Big Fish Casino',
      key: EBundlesInternalViewModel.BIG_FISH_CASINO,
      value: EBundlesInternalViewModel.BIG_FISH_CASINO
    },
    {
      content: 'Me2on',
      key: EBundlesInternalViewModel.ME2ON,
      value: EBundlesInternalViewModel.ME2ON
    },
    {
      content: 'Playsimple',
      key: EBundlesInternalViewModel.PLAY_SIMPLE,
      value: EBundlesInternalViewModel.PLAY_SIMPLE
    },
    {
      content: 'Ghost Studio',
      key: EBundlesInternalViewModel.GHOST,
      value: EBundlesInternalViewModel.GHOST
    },
    {
      content: 'Bingo Pop',
      key: EBundlesInternalViewModel.BINGO_POP,
      value: EBundlesInternalViewModel.BINGO_POP
    }
  ],
  [EBundlesViewModel.TWO_IN_ONE]: [
    {
      content: 'Loco Bingo',
      key: EBundlesInternalViewModel.LOCO_BINGO,
      value: EBundlesInternalViewModel.LOCO_BINGO
    },
    {
      content: 'PM Cashman Casino',
      key: EBundlesInternalViewModel.CASHMAN_CASINO,
      value: EBundlesInternalViewModel.CASHMAN_CASINO
    },
    {
      content: 'PM - Lightning Link Casino',
      key: EBundlesInternalViewModel.LIGHTNING_LINK_CASINO,
      value: EBundlesInternalViewModel.LIGHTNING_LINK_CASINO
    },
    {
      content: 'PM - Heart Of Vegas',
      key: EBundlesInternalViewModel.HEART_OF_VEGAS,
      value: EBundlesInternalViewModel.HEART_OF_VEGAS
    },
    {
      content: 'PM - Mighty Fu Casino',
      key: EBundlesInternalViewModel.MIGHTY_FU_CASINO,
      value: EBundlesInternalViewModel.MIGHTY_FU_CASINO
    }
  ],
  [EBundlesViewModel.THREE_IN_ONE]: [
    {
      content: 'Communix',
      key: EBundlesInternalViewModel.COMMUNIX,
      value: EBundlesInternalViewModel.COMMUNIX
    },
    {
      content: 'Candivore',
      key: EBundlesInternalViewModel.MATCH,
      value: EBundlesInternalViewModel.MATCH
    },
    {
      content: 'Candivore 2.0',
      key: EBundlesInternalViewModel.CANDIVORE,
      value: EBundlesInternalViewModel.CANDIVORE
    },
    {
      content: 'Bagelcode',
      key: EBundlesInternalViewModel.BAGELCODE,
      value: EBundlesInternalViewModel.BAGELCODE
    },
    {
      content: 'Bagelcode 2.0',
      key: EBundlesInternalViewModel.BAGELCODE2,
      value: EBundlesInternalViewModel.BAGELCODE2
    },
    {
      content: 'Pipa',
      key: EBundlesInternalViewModel.PIPA,
      value: EBundlesInternalViewModel.PIPA
    }
  ],
  [EBundlesViewModel.SIX_IN_ONE]: [
    {
      content: 'Whispers',
      key: EBundlesInternalViewModel.WHISPERS,
      value: EBundlesInternalViewModel.WHISPERS
    }
  ]
};
const sectionThemes = [
  {
    content: 'Default',
    key: ESectionViewModel.DEFAULT,
    value: ESectionViewModel.DEFAULT
  },
  {
    content: 'Bagelcode',
    key: ESectionViewModel.BAGELCODE,
    value: ESectionViewModel.BAGELCODE
  },
  {
    content: 'Pipa',
    key: ESectionViewModel.PIPA,
    value: ESectionViewModel.PIPA
  },
  {
    content: 'Whispers',
    key: ESectionViewModel.WHISPERS,
    value: ESectionViewModel.WHISPERS
  },
  {
    content: 'LLC',
    key: ESectionViewModel.LLC,
    value: ESectionViewModel.LLC
  },
  {
    content: 'MightyFu',
    key: ESectionViewModel.MIGHTY_FU_CASINO,
    value: ESectionViewModel.MIGHTY_FU_CASINO
  },
  {
    content: 'Ghost',
    key: ESectionViewModel.GHOST,
    value: ESectionViewModel.GHOST
  }
];
const balanceThemes = [
  {
    content: 'Default',
    key: EBalancesTheme.DEFAULT,
    value: EBalancesTheme.DEFAULT
  },
  {
    content: 'BagelCode',
    key: EBalancesTheme.BAGELCODE,
    value: EBalancesTheme.BAGELCODE
  },
  {
    content: 'SpaceGO',
    key: EBalancesTheme.SPACEGO,
    value: EBalancesTheme.SPACEGO
  },
  {
    content: 'Playsimple',
    key: EBalancesTheme.PLAYSIMPLE,
    value: EBalancesTheme.PLAYSIMPLE
  },
  {
    content: 'Me2on',
    key: EBalancesTheme.ME2ON,
    value: EBalancesTheme.ME2ON
  },
  {
    content: 'Pipa',
    key: EBalancesTheme.PIPA,
    value: EBalancesTheme.PIPA
  },
  {
    content: 'Whipers',
    key: EBalancesTheme.WHISPERS,
    value: EBalancesTheme.WHISPERS
  },
  {
    content: 'Ghost',
    key: EBalancesTheme.GHOST,
    value: EBalancesTheme.GHOST
  },
  {
    content: 'Jam City - Bingo Pop',
    key: EBalancesTheme.BINGO_POP,
    value: EBalancesTheme.BINGO_POP
  }
];

const StoreLayout = () => {
  const { publisherId } = useParams();
  const pictures = useImages(publisherId).getImages;
  const themeActions = useTheme(publisherId);
  const { data } = themeActions.getStoreTheme;
  const publisherActions = usePublisher(publisherId);
  const { fetchFeatureFlags } = useUsers({ publisherId });
  const dispatch = useDispatch();

  const initialBodyBlocks = useMemo(() => {
    if (!data?.storeTemplate) return;

    if (!data?.storeTemplate?.bodyBlocksOrder?.length) {
      return defaultTheme.storeTemplate.bodyBlocksOrder;
    }
    return data.storeTemplate.bodyBlocksOrder;
  }, [data?.storeTemplate]);

  const storeTemplateForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      bodyBlocksOrder: initialBodyBlocks
    },
    onSubmit: async (values) => {
      try {
        await themeActions.updateStoreTheme.mutateAsync({
          storeTemplate: values
        });
        showToast({
          message: 'Store components layout updated',
          status: EToasterStatus.SUCCESS
        });
        dispatch(uiActions.setUnsavedChanges(false));
      } catch (error) {
        showToast({
          message: 'Error updating store components layout',
          status: EToasterStatus.ERROR,
          description:
            ((error as AxiosError).response?.data as { message: string })
              .message || 'Unknown error occurred'
        });
      }
    }
  });

  const generalForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      bundlesViewModel:
        data?.general?.bundlesViewModel ||
        defaultTheme?.general?.bundlesViewModel,
      bundlesInternalViewModel:
        data?.general?.bundlesInternalViewModel ||
        defaultTheme?.general?.bundlesInternalViewModel,
      specialOffersViewModel:
        data?.general?.specialOffersViewModel ||
        defaultTheme.general.specialOffersViewModel,
      specialOffersInternalViewModel:
        data?.general?.specialOffersInternalViewModel ||
        defaultTheme.general?.specialOffersInternalViewModel,
      balancesTheme:
        data?.general?.balancesTheme || defaultTheme?.general?.balancesTheme,
      sectionViewModel:
        data?.general?.sectionViewModel ||
        defaultTheme.general.sectionViewModel,
      popupRatio: data?.general?.popupRatio || defaultTheme.general?.popupRatio
    },
    onSubmit: async (values) => {
      try {
        await themeActions.updateGeneralTheme.mutateAsync(values);
        showToast({
          message: 'Template settings updated',
          status: EToasterStatus.SUCCESS
        });
        dispatch(uiActions.setUnsavedChanges(false));
      } catch (error) {
        showToast({
          message: 'Error updating template settings',
          status: EToasterStatus.ERROR,
          description:
            ((error as AxiosError).response?.data as { message: string })
              .message || 'Unknown error occurred'
        });
      }
    }
  });

  const specialOffersOptions = useMemo(
    () =>
      (Object.keys(mapSpecialOffers) as ESpecialOfferInternalViewModel[]).map(
        (specialOfferModelName) => ({
          content: mapViewModelDisplayName[specialOfferModelName],
          key: specialOfferModelName,
          value: specialOfferModelName
        })
      ),
    []
  );

  return (
    <Grid container className="formContent">
      <Grid item xs={5}>
        {!pictures.isLoading && (
          <Stack>
            <BodyBlocks
              currentFormValue={storeTemplateForm.values.bodyBlocksOrder}
              handleChange={(newArr: string[]) =>
                storeTemplateForm.setFieldValue('bodyBlocksOrder', newArr)
              }
              submitChange={storeTemplateForm.submitForm}
              featureFlags={fetchFeatureFlags.data?.featureFlags}
              isDirty={storeTemplateForm.dirty}
            />
            <AcCard stackContainer={false} title="Special Offers">
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcSelect
                    header="Special Offers Internal"
                    name="specialOffersInternalViewModel"
                    value={generalForm.values.specialOffersInternalViewModel}
                    items={specialOffersOptions}
                    onChange={generalForm.handleChange}
                    onBlur={generalForm.handleBlur}
                    error={
                      generalForm.touched.specialOffersInternalViewModel &&
                      Boolean(generalForm.errors.specialOffersInternalViewModel)
                    }
                    tooltip="This will be the layout of your bundles."
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcSelect
                    header="Special Offers"
                    name="specialOffersViewModel"
                    value={generalForm.values.specialOffersViewModel}
                    items={[
                      {
                        content: generalForm.values.specialOffersViewModel,
                        key: generalForm.values.specialOffersViewModel,
                        value: generalForm.values.specialOffersViewModel
                      }
                    ]}
                    onChange={generalForm.handleChange}
                    onBlur={generalForm.handleBlur}
                    error={
                      generalForm.touched.specialOffersViewModel &&
                      Boolean(generalForm.errors.specialOffersViewModel)
                    }
                    tooltip="This will be the layout of your bundles."
                  />
                </Grid>
              </Grid>
            </AcCard>
            <Divider />
            <AcCard stackContainer={false} title="Bundles">
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcSelect
                    header="Store Template"
                    name="bundlesViewModel"
                    value={generalForm.values.bundlesViewModel}
                    items={[
                      {
                        content: '1 in a row',
                        key: EBundlesViewModel.LINEAR,
                        value: EBundlesViewModel.LINEAR
                      },
                      {
                        content: '2 in a row',
                        key: EBundlesViewModel.TWO_IN_ONE,
                        value: EBundlesViewModel.TWO_IN_ONE
                      },
                      {
                        content: '3 in a row',
                        key: EBundlesViewModel.THREE_IN_ONE,
                        value: EBundlesViewModel.THREE_IN_ONE
                      },
                      {
                        content: '6 in a row',
                        key: EBundlesViewModel.SIX_IN_ONE,
                        value: EBundlesViewModel.SIX_IN_ONE
                      }
                    ]}
                    onChange={(e) => {
                      generalForm.setFieldValue(
                        'bundlesInternalViewModel',
                        mapBundleTypesBasedOnLayout[
                          e.target.value as EBundlesViewModel
                        ][0].key
                      );
                      generalForm.handleChange(e);
                    }}
                    onBlur={generalForm.handleBlur}
                    error={
                      generalForm.touched.bundlesViewModel &&
                      Boolean(generalForm.errors.bundlesViewModel)
                    }
                    tooltip="This will be the layout of your store."
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcSelect
                    header="Bundle Template"
                    name="bundlesInternalViewModel"
                    value={generalForm.values.bundlesInternalViewModel}
                    items={
                      mapBundleTypesBasedOnLayout[
                        generalForm.values.bundlesViewModel
                      ]
                    }
                    onChange={generalForm.handleChange}
                    onBlur={generalForm.handleBlur}
                    error={
                      generalForm.touched.bundlesInternalViewModel &&
                      Boolean(generalForm.errors.bundlesInternalViewModel)
                    }
                    tooltip="This will be the layout of your bundles."
                  />
                </Grid>
                <Grid item xs={12}>
                  <AcSelect
                    header="Section Theme"
                    name="sectionViewModel"
                    value={generalForm.values.sectionViewModel}
                    items={sectionThemes}
                    onChange={generalForm.handleChange}
                    onBlur={generalForm.handleBlur}
                    error={
                      generalForm.touched.sectionViewModel &&
                      Boolean(generalForm.errors.sectionViewModel)
                    }
                    tooltip="This will be the layout of your sections."
                  />
                </Grid>
              </Grid>
            </AcCard>
            <Divider />
            <AcCard stackContainer={false} title="Popups">
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcSelect
                    header="Popup ratio"
                    name="popupRatio"
                    value={generalForm.values.popupRatio}
                    items={[
                      {
                        content: '1:1',
                        key: BannerAspectRatio.SQUARE,
                        value: BannerAspectRatio.SQUARE
                      },
                      {
                        content: '2:3',
                        key: BannerAspectRatio.TWO_ON_THREE,
                        value: BannerAspectRatio.TWO_ON_THREE
                      }
                    ]}
                    onChange={generalForm.handleChange}
                    onBlur={generalForm.handleBlur}
                    tooltip="This will be the ratio of your popups"
                  />
                </Grid>
              </Grid>
            </AcCard>
            <Divider />
            <AcCard stackContainer={false} title="Balance">
              <Grid container rowSpacing={2} columnSpacing={1.5}>
                <Grid item xs={12}>
                  <AcSelect
                    header="Balance Theme"
                    name="balancesTheme"
                    value={generalForm.values.balancesTheme}
                    placeholder="Select"
                    items={balanceThemes}
                    onChange={generalForm.handleChange}
                    onBlur={generalForm.handleBlur}
                    error={
                      generalForm.touched.balancesTheme &&
                      Boolean(generalForm.errors.balancesTheme)
                    }
                  />
                </Grid>
              </Grid>
            </AcCard>
          </Stack>
        )}
        <Grid item xs={6} mt={3}>
          <ActionButton
            variant="outlined"
            text="Update"
            onClick={generalForm.submitForm}
            disabled={
              !generalForm.isValid ||
              !generalForm.dirty ||
              generalForm.isSubmitting
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StoreLayout;
