import { ComponentProps, FC, useState } from 'react';

import { CheckedState } from '@radix-ui/react-checkbox';

import { cn } from '../lib/utils';
import { UICheckbox, UILabel, UISwitch } from '../ui';

export type CheckboxProps = ComponentProps<typeof UISwitch> & {
  label?: string;
  description?: string;
  defaultChecked?: boolean;
  isDisabled?: boolean;
  variant?: 'default' | 'outlined';
  className?: string;
};

const Checkbox: FC<CheckboxProps> = ({
  label,
  description,
  defaultChecked = false,
  isDisabled = false,
  variant = 'default',
  className,
  ...rest
}) => {
  const [checked, setChecked] = useState(defaultChecked);

  const handleCheckedChange = (checkedState: CheckedState) => {
    setChecked(!!checkedState);
  };

  const computedClassName = cn(
    'w-4 h-4 border flex items-center justify-center mt-0.5 box-content rounded',
    'data-[state=checked]:border-base-primary',
    '[&>span>svg]:h3',
    '[&>span>svg]:w-3',
    'data-[state=unchecked]:bg-base-background data-[state=unchecked]:border-base-primary data-[state=unchecked]:shadow-none',
    'data-[state=disabled]:bg-muted-foreground data-[state=disabled]:transparent data-[state=disabled]:text-bg-muted-foreground data-[state=disabled]:shadow-none data-[state=checked]:shadow-none',
    variant === 'outlined'
      ? 'data-[state=checked]:bg-base-background data-[state=checked]:text-base-primary data-[state=unchecked]:bg-base-background'
      : 'data-[state=checked]:bg-base-primary data-[state=checked]:text-base-background',
    className
  );

  return (
    <div className={'flex space-x-2'}>
      <UICheckbox
        {...rest}
        checked={checked}
        onCheckedChange={handleCheckedChange}
        id="toggle-checkbox"
        disabled={isDisabled}
        className={computedClassName}
      />

      <div className="flex flex-col">
        {label && (
          <UILabel
            htmlFor="toggle-checkbox"
            className={cn(
              'text-p-s',
              isDisabled ? 'text-base-mutedForeground' : 'text-base-foreground'
            )}
          >
            {label}
          </UILabel>
        )}
        {description && (
          <span className="text-p-s text-base-mutedForeground mt-1.5">
            {description}
          </span>
        )}
      </div>
    </div>
  );
};

export default Checkbox;
