import React, { FC, ReactNode } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import Button from '../Button/Button';
import Input from '../Input/Input';
import Typography from '../Typography/Typography';

type PortalHeaderProps = {
  title: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  isSearchVisible?: boolean;
  isCreateButton?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  primaryButtonIcon?: ReactNode;
  secondaryButtonIcon?: ReactNode;
  primaryButtonVariant?:
    | 'default'
    | 'outline'
    | 'secondary'
    | 'ghost';
  secondaryButtonVariant?:
    | 'default'
    | 'outline'
    | 'secondary'
    | 'ghost';
};

const PortalHeader: FC<PortalHeaderProps> = ({
  title,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  isSearchVisible,
  isCreateButton,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonIcon,
  secondaryButtonIcon,
  primaryButtonVariant,
  secondaryButtonVariant
}) => {
  return (
    <div className="px-9 pt-8 mb-5">
      <div className="flex justify-between items-center">
        <Typography className="text-hd-h3">{title}</Typography>
        <div className="flex items-center gap-2.5">
          {isSearchVisible && (
            <Input
              rightIcon={
                <FontAwesomeIcon icon="fa-regular fa-magnifying-glass" />
              }
              placeholder="Search"
              className="w-[300px]"
              isHorizontal
            />
          )}
          {secondaryButtonText && (
            <Button
              onClick={onSecondaryButtonClick}
              leftIcon={secondaryButtonIcon}
              variant={secondaryButtonVariant}
            >
              {secondaryButtonText}
            </Button>
          )}
          {primaryButtonText && (
            <Button
              isCreateButton={isCreateButton}
              onClick={onPrimaryButtonClick}
              leftIcon={primaryButtonIcon}
              variant={primaryButtonVariant}
            >
              {primaryButtonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PortalHeader;
