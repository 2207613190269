import { RadioButtonOptionsProps } from 'components/RadioButton/RadioButton';
import * as z from 'zod';

export enum EContentRadioButtonOptions {
  HIDDEN = 'hidden',
  VISIBLE = 'visible'
}

export const CONTENT_RADIO_BUTTON_OPTIONS: RadioButtonOptionsProps[] = [
  {
    value: EContentRadioButtonOptions.VISIBLE,
    id: EContentRadioButtonOptions.VISIBLE,
    label: 'Visible'
  },
  {
    value: EContentRadioButtonOptions.HIDDEN,
    id: EContentRadioButtonOptions.HIDDEN,
    label: 'Hidden'
  }
];

export const CONTENT_FORM_SCHEMA = z.object({
  title: z
    .string()
    .trim()
    .min(1, 'Title is required and must have at least 1 character'),
  subTitle: z.string().optional(),
  bodyText: z.string().optional(),
  headerImage: z.string().trim().min(1, 'Header Image is required'),
  thumbnailImage: z.string().trim().min(1, 'Thumbnail image is required'),
  pages: z.string().optional(),
  labels: z
    .string()
    .min(1, 'Label are required and must have at least 1 character'),
  slug: z.string().optional(),
  state: z.enum([
    EContentRadioButtonOptions.VISIBLE,
    EContentRadioButtonOptions.HIDDEN
  ]),
  publishDate: z.string().optional()
});

export const DEFAULT_PAGE_OPTION = 'Select page';
