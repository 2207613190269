import React, { useState } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import Button from '../../Button/Button';
import Input from '../../Input/Input';
import { cn } from '../../lib/utils';
import Typography from '../../Typography/Typography';

const UploadContent = () => {
  const [isDragging, setIsDragging] = useState(false);

  return (
    <div className="flex flex-col gap-4">
      <div
        onDragOver={(e) => {
          e.preventDefault();
          setIsDragging(true);
        }}
        onDragLeave={() => setIsDragging(false)}
        onDrop={(e) => {
          e.preventDefault();
          setIsDragging(false);
        }}
        className={cn(
          'w-full h-[300px] flex flex-col items-center justify-center bg-violet-50 rounded-md border-dashed border border-indigo-200',
          isDragging && 'border-indigo-600 border-solid'
        )}
      >
        <Typography className="text-p-xl text-indigo-600 mb-0.5">
          Start adding your files
        </Typography>
        <Typography className="text-p-s text-base-mutedForeground">
          Drag and drop files or upload from your computer
        </Typography>
        <Button
          className="mt-6"
          isUploadButton
          rightIcon={<FontAwesomeIcon icon="fa-solid fa-plus" />}
        >
          Upload
        </Button>
      </div>
      <div className="rounded-md border border-base-input px-3 py-2.5 flex items-center justify-space-between max-w-[596px]">
        <FontAwesomeIcon
          icon="fa-regular fa-circle-check"
          className="text-indigo-600 mr-2.5"
        />
        <Typography className="text-p-s font-bold text-indigo-600 truncate mr-3">
          Screenshot MacBook Pro16 Test Env and More Text To Write 2025-03-18 at
          12.36.28
        </Typography>
        <FontAwesomeIcon
          icon="fa-regular fa-trash"
          className="text-base-foreground ml-auto cursor-pointer min-w-4"
        />
      </div>
      <Input placeholder="* ID Number" />
    </div>
  );
};

export default UploadContent;
