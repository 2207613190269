import { useMutation, useQuery } from '@tanstack/react-query';

import { Page } from '../../common/contracts';
import { EQueryKeys } from '../../constants/enums';
import useAxios from '../../hooks/useAxios';
import { EApiRoutes, fetchConfig, getApiRouteOrMock } from '../api.utils';

export function usePages(pageId?: string) {
  const axios = useAxios();

  const getPages = useQuery({
    ...fetchConfig.list,
    queryKey: [EQueryKeys.PAGES],
    queryFn: async () => {
      return await axios.get(getApiRouteOrMock(EApiRoutes.PAGES));
    }
  });

  const getPage = useQuery({
    ...fetchConfig.single,
    queryKey: [`${EQueryKeys.PAGES}_${pageId}`],
    queryFn: async () => {
      return await axios.get(
        `${getApiRouteOrMock(EApiRoutes.PAGES)}/${pageId}`
      );
    },
    enabled: !!pageId
  });

  const addPage = useMutation(async (newPage: Page) => {
    return await axios.post(getApiRouteOrMock(EApiRoutes.PAGES), newPage);
  }, {});

  const deletePage = useMutation(async (pageIdToDelete: string) => {
    return await axios.del(
      `${getApiRouteOrMock(EApiRoutes.PAGES)}/${pageIdToDelete}`
    );
  }, {});

  const updatePage = useMutation(
    async ({ form, pageId }: { form: Partial<Page>; pageId: string }) => {
      return axios.put(
        `${getApiRouteOrMock(EApiRoutes.PAGES)}/${pageId}`,
        form
      );
    },
    {}
  );

  return {
    getPages,
    getPage,
    addPage,
    deletePage,
    updatePage
  };
}
