import React, { FC } from 'react';

import { cn } from '../lib/utils';
import Typography from '../Typography/Typography';

type RichTextProps = {
  title?: string;
  description?: string;
  className?: string;
};

const RichText: FC<RichTextProps> = ({ title, description, className }) => {
  if (!title && !description) return null;

  return (
    <div className={cn('flex flex-col gap-1.5', className)}>
      <Typography tag="h4" className={cn('text-base-cardForeground')}>
        {title}
      </Typography>
      <Typography className={cn('text-p-m text-base-mutedForeground')}>
        {description}
      </Typography>
    </div>
  );
};

export default RichText;
