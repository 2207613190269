import React from 'react';

import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { usePages } from '../../api/portal/usePagesApi';
import AnimatedLoadingLogo from '../../components/AnimatedLoadingLogo/AnimatedLoadingLogo';
import Checkbox from '../../components/Checkbox/Checkbox';
import Chip, { ChipStatus } from '../../components/Chip/Chip';
import ActionCell from '../../components/DataTableComponent/components/ActionCell';
import DataTable from '../../components/DataTableComponent/DataTable';
import EmptyState from '../../components/EmptyState/EmptyState';
import PortalHeader from '../../components/PortalHeader/PortalHeader';
import RichText from '../../components/RichText/RichText';
import { EToasterStatus, showToast } from '../../utils/showToast';

const dayjsUTC = dayjs.extend(utc);

const PagesTable = () => {
  const navigate = useNavigate();
  const { getPages, deletePage } = usePages();

  const isLoading = getPages.isLoading || !getPages.data;
  const hasPages = getPages.data?.pages && getPages.data.pages.length > 0;
  const isEmpty = getPages.data?.pages && getPages.data.pages.length === 0;

  const handleDeletePage = async (pageId: string) => {
    if (!pageId) return;
    deletePage.mutate(pageId, {
      onSuccess: () => {
        showToast({
          message: 'Page deleted successfully',
          status: EToasterStatus.SUCCESS
        });
        getPages.refetch();
      },
      onError: (e: any) => {
        showToast({
          message: 'Failed to delete page',
          status: EToasterStatus.ERROR,
          description: e.response.data.message ?? ''
        });
      }
    });
  };

  const COLUMNS: any = [
    {
      accessorKey: 'checkbox',
      header: <Checkbox variant="outlined" />,
      cell: () => <Checkbox variant="outlined" />
    },
    {
      accessorKey: 'pageName',
      header: 'Page Name',
      cell: ({ getValue }: any) => getValue()
    },
    {
      accessorKey: 'pageTemplate',
      header: 'Page Type',
      cell: ({ getValue }: any) => getValue()
    },
    {
      accessorKey: 'createdAt',
      header: 'Created Date',
      cell: ({ getValue }: any) =>
        dayjsUTC.utc(getValue()).format('MMM DD, YYYY')
    },
    {
      accessorKey: 'publishDate',
      header: 'Last published',
      cell: ({ getValue }: any) =>
        dayjsUTC.utc(getValue()).format('MMM DD, YYYY')
    },
    {
      accessorKey: 'state',
      header: 'Status',
      cell: ({ getValue }: any) => {
        const statusMap: Record<string, ChipStatus> = {
          visible: 'success',
          hidden: 'primary',
          scheduled: 'warning'
        };
        return (
          <Chip variant="dot" status={statusMap[getValue()]}>
            {getValue()}
          </Chip>
        );
      }
    },
    {
      accessorKey: 'actions',
      header: null,
      cell: ({ row }: any) => (
        <ActionCell
          rowData={row.original}
          actions={[
            {
              label: 'Edit',
              handler: () => {
                navigate(`./form/${row.original.id}`);
              }
            },
            {
              label: 'Duplicate',
              handler: () => {
                navigate(`./dup/${row.original.id}`);
              }
            },
            {
              label: 'Delete',
              handler: () => handleDeletePage(row.original.id)
            }
          ]}
        />
      )
    }
  ];

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="flex justify-center items-center h-3/4">
          <AnimatedLoadingLogo />
        </div>
      );
    }

    if (isEmpty) {
      return (
        <EmptyState
          title="Create a portal page"
          description="Build your portal by adding pages to fit your needs"
          buttonText="Start now"
          className="mx-auto mt-36"
          onClick={() => navigate('./form')}
        />
      );
    }

    return (
      <div className="border border-base-sidebarBorder rounded-[20px] mx-7 pt-9 px-9 pb-56 mb-24">
        <RichText
          title="Pages"
          description="Create, view, and manage pages in your portal"
          className="mb-11"
        />
        <div className="border border-base-sidebarBorder rounded-[20px]">
          <DataTable data={getPages.data.pages} columns={COLUMNS} />
        </div>
      </div>
    );
  };

  return (
    <div className="overflow-y-scroll">
      <PortalHeader
        title={isEmpty ? 'Pages' : 'My pages'}
        primaryButtonText="Create New page"
        onPrimaryButtonClick={() => navigate('./form')}
        isSearchVisible={hasPages}
        isCreateButton
      />
      {renderContent()}
    </div>
  );
};

export default PagesTable;
