import { useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';

import { PageContentData } from '../../common/contracts';
import { EQueryKeys } from '../../constants/enums';
import useAxios from '../../hooks/useAxios';
import { EApiRoutes, fetchConfig, getApiRouteOrMock } from '../api.utils';

const useContentManagement = () => {
  const axios = useAxios();
  const { contentId } = useParams();

  const getCreatedContentsData = useQuery({
    ...fetchConfig.list,
    queryKey: [EQueryKeys.CONTENT_MANAGEMENT_GET_CONTENTS_DATA],
    queryFn: async () => {
      return await axios.get(
        `${getApiRouteOrMock(EApiRoutes.CONTENT_MANAGEMENT)}`,
        {}
      );
    }
  });

  const createPageContent = useMutation(
    async (contentData: PageContentData) => {
      const response = await axios.post(
        `${getApiRouteOrMock(EApiRoutes.CONTENT_MANAGEMENT)}`,
        contentData
      );
      return response.data;
    },
    {}
  );

  const getPageContentById = useQuery({
    ...fetchConfig.single,
    queryKey: [`${EQueryKeys.BADGES}_${contentId}`],
    queryFn: async () => {
      return await axios.get(
        `${getApiRouteOrMock(EApiRoutes.CONTENT_MANAGEMENT)}/${contentId}`,
        {}
      );
    },
    enabled: !!contentId
  });

  const editPageContent = useMutation(
    async (newContentData: PageContentData) => {
      const response = await axios.put(
        `${getApiRouteOrMock(EApiRoutes.CONTENT_MANAGEMENT)}/${contentId}`,
        newContentData
      );
      return response.data;
    },
    {}
  );

  const deletePageContent = useMutation(async (contentIdToDelete: string) => {
    return await axios.del(
      `${getApiRouteOrMock(EApiRoutes.CONTENT_MANAGEMENT)}/${contentIdToDelete}`
    );
  }, {});

  return {
    getCreatedContentsData,
    createPageContent,
    getPageContentById,
    editPageContent,
    deletePageContent
  };
};

export default useContentManagement;
