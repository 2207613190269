import { useState } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import { permissionsUtil } from 'utils/permissionsUtil';
import { AssetsCategories } from 'views/Assets/modals/constants/enums';

import { ImageItemProps } from './ImageItem.types';

import './style.scss';

const ImageItem = ({
  deleteImage,
  data,
  classes,
  uploading
}: ImageItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const open = Boolean(anchorEl);

  const deleteImageHandler = () => {
    deleteImage(data._id);
    handleClose();
  };

  const handleOpen = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenUrl = (url: string) => {
    window.open(url, '_blank');
    handleClose();
  };

  const isDeleteBtnShown =
      permissionsUtil.isActionEnabled(
          null,
          EPermissionGroup.ASSET_LIBRARY,
          EPermissionAction.MANAGE
      );

  return (
    <Box className={`imageItem ${classes}`}>
      <Stack className="asset-tag">
        {AssetsCategories[data.type as keyof typeof AssetsCategories]}
      </Stack>
      <Box className="imageHolder">
        {uploading ? (
          <CircularProgress className="loader" />
        ) : (
          <img alt={data.name} src={data.url} />
        )}
      </Box>
      <Box className="imageFooter">
        {!uploading && (
          <>
            <Typography>
              {data.name.length > 20
                ? `${data.name.substring(0, 15)}...`
                : data.name}
            </Typography>
            <IconButton onClick={handleOpen}>
              <MoreHorizIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem onClick={() => handleOpenUrl(data.url)}>
                <OpenInNewIcon sx={{ marginRight: '8px' }} />
                Open URL
              </MenuItem>
              {isDeleteBtnShown && (
                <MenuItem
                  onClick={deleteImageHandler}
                  disabled={!permissionsUtil.canUserEdit()}
                >
                  <DeleteOutlineIcon
                    sx={{ marginRight: '8px' }}
                    className="danger"
                  />
                  <Typography className="danger">Delete image</Typography>
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ImageItem;
