import React, { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';

import DataTable from 'components/DataTableComponent/DataTable';

import useContentManagement from '../../../api/portal/useContentManagement';
import EmptyState from '../../../components/EmptyState/EmptyState';
import PortalHeader from '../../../components/PortalHeader/PortalHeader';
import RichText from '../../../components/RichText/RichText';
import { EToasterStatus, showToast } from '../../../utils/showToast';

import { getContentTableColumns } from './tableConfig';

export type ContentTableDataType = {
  checkbox: null;
  title: string;
  creationDate: string;
  lastPublishedDate: string;
  state: 'visible' | 'hidden' | 'scheduled';
  actions: null;
  page?: string;
};

const ContentTable = () => {
  const { getCreatedContentsData, deletePageContent } = useContentManagement();
  const navigate = useNavigate();
  const createdContentsData = getCreatedContentsData?.data?.contents;
  const isLoading = getCreatedContentsData?.isLoading;

  const handleDeleteSelectedContent = async (contentId: string) => {
    try {
      await deletePageContent.mutateAsync(contentId);
      await getCreatedContentsData.refetch();
      showToast({
        message: 'Page content was successfully deleted',
        status: EToasterStatus.SUCCESS
      });
    } catch (error: any) {
      showToast({
        message:
          error?.response?.data?.message ||
          'Page content was not deleted. Please try again',
        status: EToasterStatus.SUCCESS
      });
    }
  };

  const columns: ColumnDef<Partial<ContentTableDataType>>[] = useMemo(
    () => getContentTableColumns(handleDeleteSelectedContent, navigate),
    [handleDeleteSelectedContent, navigate]
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <CircularProgress className="absolute top-1/2 left-1/2 h-16 w-16" />
      );
    }
    return createdContentsData?.length > 0 ? (
      <div className="border border-base-sidebarBorder rounded-[20px] mx-7 p-9">
        <RichText
          title="Content"
          description="Create, view, and manage content in your portal"
          className="mb-11"
        />
        <div className="border border-base-sidebarBorder rounded-[20px]">
          <DataTable
            data={createdContentsData}
            columns={columns}
            showPagination={false}
          />
        </div>
      </div>
    ) : (
      <EmptyState
        title="Create content"
        description="Write content that you can add to pages in your portal"
        buttonText="Start now"
        className="mx-auto mt-36"
        onClick={() => navigate('./form')}
      />
    );
  };

  return (
    <div className="h-screen overflow-scroll">
      <PortalHeader
        title={createdContentsData?.length > 0 ? 'My content' : 'Content'}
        primaryButtonText="Create New content"
        onPrimaryButtonClick={() => navigate('./form')}
        isSearchVisible={createdContentsData?.length > 0}
        isCreateButton
      />
      {renderContent()}
    </div>
  );
};

export default ContentTable;
