import React, { FC, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@appcharge/shared-ui';
import { AxiosError } from 'axios';

import useImages from '../../api/useImages';
import useUsers from '../../api/useUsers';
import { EFeatureFlag } from '../../constants/enums';
import { EToasterStatus, showToast } from '../../utils/showToast';
import Button from '../Button/Button';
import { cn } from '../lib/utils';
import Typography from '../Typography/Typography';
import { UIInput } from '../ui';
import { Dialog, DialogOverlay, DialogTrigger } from '../ui/dialog';

import { ImagePreview } from './ImagePreview';
import ImageUploadModal from './ImageUploadModal/ImageUploadModal';

type ImageUploadProps = {
  title?: string;
  description?: string;
  buttonText?: string;
  className?: string;
  onUpload?: (url: string) => void;
  isRequired?: boolean;
};

const ImageUpload: FC<ImageUploadProps> = ({
  title = '',
  description = '',
  buttonText = 'Upload',
  className,
  onUpload,
  isRequired = false
}) => {
  // const { publisherId } = useParams();
  // const inputRef = useRef<HTMLInputElement>(null);
  // const [preview, setPreview] = useState<string | null>(null);
  // const [isUploading, setIsUploading] = useState(false);
  //
  // const { addImage, getImages } = useImages(publisherId);
  // const { fetchFeatureFlags } = useUsers({
  //   publisherId
  // });
  //
  // const hasFeatureFlagCompressUploadedImages =
  //   fetchFeatureFlags.data?.featureFlags?.[
  //     EFeatureFlag.DASHBOARD_COMPRESS_UPLOADED_IMAGES
  //   ];
  //
  // const doUpload = (file: File) => {
  //   if (!file) return;
  //   if (file.size > 2000000) {
  //     showToast({
  //       message: 'File size limit is 2MB',
  //       status: EToasterStatus.ERROR
  //     });
  //     return;
  //   }
  //   setIsUploading(true);
  //
  //   const formData = new FormData();
  //   formData.append('image', file);
  //   formData.append('type', 'general');
  //   formData.append('name', file.name);
  //
  //   addImage.mutate(formData, {
  //     onSuccess: (data) => {
  //       showToast({
  //         message: hasFeatureFlagCompressUploadedImages
  //           ? 'File has been compressed and uploaded successfully'
  //           : 'File has been uploaded successfully',
  //         status: EToasterStatus.SUCCESS
  //       });
  //       getImages.refetch();
  //       const url = data?.data?.url;
  //       setPreview(url);
  //       onUpload?.(url);
  //     },
  //     onError: (error) => {
  //       console.error(error);
  //       showToast({
  //         message: 'Asset has failed to upload',
  //         status: EToasterStatus.ERROR,
  //         description: (
  //           (error as AxiosError).response?.data as { message: string }
  //         )?.message
  //       });
  //     },
  //     onSettled: () => {
  //       setIsUploading(false);
  //     }
  //   });
  // };
  //
  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files?.[0];
  //   if (!file) return;
  //   doUpload(file);
  // };
  //
  // const handleButtonClick = () => {
  //   inputRef.current?.click();
  // };
  //
  // const handleRemoveImage = (event: React.MouseEvent<SVGSVGElement>) => {
  //   event.stopPropagation();
  //   setPreview(null);
  //   if (inputRef.current) {
  //     inputRef.current.value = '';
  //   }
  //   onUpload?.('');
  // };
  //
  // const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
  //   event.preventDefault();
  // };
  //
  // const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
  //   event.preventDefault();
  //   const file = event.dataTransfer.files?.[0];
  //   if (!file) return;
  //   if (file.size > 2000000) {
  //     showToast({
  //       message: 'File size limit is 2MB',
  //       status: EToasterStatus.ERROR
  //     });
  //     return;
  //   }
  //   doUpload(file);
  // };

  return (
    <Dialog>
      <DialogOverlay className="bg-alpha-80" />
      <div
        className={cn(
          'flex justify-between items-center w-[427px] py-8 px-11 bg-base-secondary rounded-md border-dashed border border-base-mutedForeground',
          // preview && 'py-2.5 px-6',
          className
        )}
        // onDragOver={handleDragOver}
        // onDrop={handleDrop}
      >
        <div>
          <Typography className="flex font-medium text-p-s mb-1.5">
            {isRequired && (
              <div className="mr-1 mt-[-2px]">
                <FontAwesomeIcon
                  icon="fa-regular fa-asterisk"
                  className="h-2 w-2 text-[8px]"
                />
              </div>
            )}
            {title}
          </Typography>
          <Typography className="text-p-xs text-base-sidebarRing whitespace-pre-line">
            {description}
          </Typography>
        </div>
        <UIInput
          type="file"
          accept="image/png, image/jpeg, image/svg+xml"
          // ref={inputRef}
          // onChange={handleInputChange}
          className="hidden"
        />
        <DialogTrigger asChild>
          <Button isUploadButton>{buttonText}</Button>
        </DialogTrigger>
        <ImageUploadModal />
        {/*{isUploading ? (*/}
        {/*  <div>Loading...</div>*/}
        {/*) : preview ? (*/}
        {/*  <ImagePreview*/}
        {/*    preview={preview}*/}
        {/*    handleButtonClick={handleButtonClick}*/}
        {/*    handleRemoveImage={handleRemoveImage}*/}
        {/*  />*/}
        {/*) : (*/}
        {/*  <Button isUploadButton onClick={handleButtonClick}>*/}
        {/*    {buttonText}*/}
        {/*  </Button>*/}
        {/*)}*/}
      </div>
    </Dialog>
  );
};

export default ImageUpload;
