import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@appcharge/shared-ui';
import { CellContext, type ColumnDef } from '@tanstack/react-table';
import useCheckoutLinks from 'api/useCheckoutLinks';
import useOffers from 'api/useOffers';
import type {
  Offer,
  ProductsSequence,
  TimeFrameObject
} from 'common/contracts';
import { DATE_TIME_FORMAT } from 'constants/constants';
import { EAppearanceStatus, EOfferStatus, OfferType } from 'constants/enums';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getConvertedPrice } from 'utils/getConvertedPrice';
import { appearanceUtils, statusUtils } from 'utils/scheduleUtils';
import { statusFilterOptions } from 'utils/selectOptionsUtils';
import { EToasterStatus, showToast } from 'utils/showToast';

import AnimatedLoadingLogo from 'components/AnimatedLoadingLogo/AnimatedLoadingLogo';
import Button from 'components/Button/Button';
import type { ChipStatus } from 'components/Chip/Chip';
import Chip from 'components/Chip/Chip';
import FilterDropdownCard from 'components/Filter/FilterDropdownCard';
import FilterSearchCard from 'components/Filter/FilterSearchCard';

import ActionCell from '../../components/DataTableComponent/components/ActionCell';
import DataTable from '../../components/DataTableComponent/DataTable';
import EmptyState from '../../components/EmptyState/EmptyState';
import PortalHeader from '../../components/PortalHeader/PortalHeader';

const dayjsUTC = dayjs.extend(utc);

type RowData = {
  publisherOfferId: string;
  offerId: string;
  productsSequence: ProductsSequence[];
  createdAt: string;
  expirationDate: string;
  status: string;
  active: boolean;
  schedule?: {
    permanent: boolean;
    interval?: string;
    timeFrames?: TimeFrameObject[];
  };
};

const statusMapping: Record<string, ChipStatus> = {
  [EOfferStatus.RUNNING]: 'success',
  [EOfferStatus.PERMANENT]: 'active',
  [EOfferStatus.UPCOMING]: 'warning',
  [EOfferStatus.ENDED]: 'secondary',
  [EOfferStatus.INTERVAL]: 'optional',
  [EOfferStatus.INACTIVE]: 'danger'
};

const CheckoutLinksTable = () => {
  const navigate = useNavigate();
  const { publisherId } = useParams();

  const { getOffers, deleteOffer, updateOffer } = useOffers({
    offerId: undefined,
    offerType: OfferType.CHECKOUT_LINK
  });
  const { generateOrGetCheckoutLink } = useCheckoutLinks(publisherId);
  const [selectedOffer, setSelectedOffer] = useState<string | null>(null);
  const [skuFilterValue, setSkuFilterValue] = useState<string>('');
  const [statusFilterValue, setStatusFilterValue] = useState<string>('');

  const isLoading = getOffers.isLoading || !getOffers.data;
  const hasOffers = getOffers.data?.pages && getOffers.data.pages.length > 0;
  const isEmpty = getOffers.data?.pages && getOffers.data.pages.length === 0;

  useEffect(() => {
    if (!selectedOffer) return;
    deleteOffer.mutate(selectedOffer, {
      onSuccess: () => {
        showToast({
          message: 'Checkout link deleted successfully',
          status: EToasterStatus.SUCCESS
        });
        getOffers.refetch();
      },
      onError: (e: any) => {
        showToast({
          message: 'Failed to delete checkout link',
          status: EToasterStatus.ERROR,
          description: e.response.data.message ?? ''
        });
      },
      onSettled: () => {
        setSelectedOffer(null);
      }
    });
  }, [selectedOffer]);

  const filteredData = getOffers.isLoading
    ? []
    : getOffers?.data?.offers.filter((offer: Offer) => {
        const isSkuMatch = offer.publisherOfferId
          .toLowerCase()
          .includes(skuFilterValue.toLowerCase());

        const offerStatus = statusUtils.getOfferStatus(offer, offer.active);

        if (offerStatus === EOfferStatus.INACTIVE) {
          return statusFilterValue === EOfferStatus.INACTIVE;
        }
        return (
          (!statusFilterValue || statusFilterValue === offerStatus) &&
          isSkuMatch
        );
      });

  const columns: ColumnDef<RowData, unknown>[] = [
    {
      accessorKey: 'copy',
      header: 'Links',
      cell: (cell: CellContext<RowData, unknown>) => {
        const { offerId } = cell.row.original;

        return (
          <Button
            onClick={() => handleGetCheckoutLink(offerId)}
            className="text-gray-600 hover:text-black w-[16px]"
            variant="ghost"
          >
            <FontAwesomeIcon icon="fa-solid fa-link" />
          </Button>
        );
      }
    },
    {
      accessorKey: 'publisherOfferId',
      header: 'External ID',
      cell: (cell: CellContext<RowData, unknown>) => cell.getValue()
    },
    {
      accessorKey: 'name',
      header: 'Name',
      cell: (cell: CellContext<RowData, unknown>) => cell.getValue()
    },
    {
      accessorKey: 'price',
      header: 'Price',
      cell: (cell: CellContext<RowData, unknown>) => {
        const productsSequence = cell.row.original
          .productsSequence as ProductsSequence[];
        return getConvertedPrice({
          row: { productsSequence: productsSequence || [] }
        });
      }
    },
    {
      accessorKey: 'createdAt',
      header: 'Created at',
      cell: (cell: CellContext<RowData, unknown>) =>
        dayjsUTC.utc(cell.getValue() as boolean).format(DATE_TIME_FORMAT)
    },
    {
      accessorKey: 'expirationDate',
      header: 'Expiration date',
      cell: (cell: CellContext<RowData, unknown>) => {
        const createdAt = cell.row.original.createdAt;
        if (!createdAt) return <div className="text-black">-</div>;

        const expirationDate = new Date(createdAt);
        expirationDate.setMonth(expirationDate.getMonth() + 6);

        return dayjs(expirationDate).format(DATE_TIME_FORMAT);
      }
    },
    {
      accessorKey: 'active',
      header: 'Status',
      cell: (cell: CellContext<RowData, unknown>) => {
        const isActive = cell.getValue() as boolean;
        const status = statusUtils.getOfferStatus(cell.row.original, isActive);

        return (
          <Chip variant="flat" status={statusMapping[status]}>
            {status}
          </Chip>
        );
      }
    },
    {
      accessorKey: 'nextAppearance',
      header: 'Next appearance',
      cell: (cell: CellContext<RowData, unknown>) => {
        const schedule = cell.row.original.schedule;

        const { status, appearanceTime } =
          appearanceUtils.getStrongestStatusWithTime(schedule?.timeFrames);

        if (schedule?.permanent && schedule?.interval) {
          return appearanceUtils.getNextAppearanceByRefreshTime(
            schedule.interval
          );
        }

        if (
          (schedule?.permanent && !schedule?.interval) ||
          status !== EAppearanceStatus.UPCOMING
        ) {
          return '-';
        }
        return appearanceTime;
      }
    },
    {
      accessorKey: 'actions',
      header: '',
      maxSize: 10,
      size: 10,
      cell: (cell: CellContext<RowData, unknown>) => {
        const { original } = cell.row;
        return (
          <ActionCell
            rowData={original}
            actions={[
              {
                label: original.active ? 'Deactivate' : 'Activate',
                handler: () =>
                  handleToggleActiveStatus(original.offerId, original.active)
              },
              {
                label: 'Edit',
                handler: () => navigate(`./form/${original.offerId}`)
              },
              {
                label: 'Duplicate',
                handler: () => navigate(`./dup/${original.offerId}`)
              },
              {
                label: 'Delete',
                handler: () => {
                  setSelectedOffer(original.offerId);
                }
              }
            ]}
          />
        );
      }
    }
  ];

  const handleGetCheckoutLink = async (offerId: string) => {
    await generateOrGetCheckoutLink.mutateAsync(
      { offerId },
      {
        onSuccess: (data: any) => {
          // Create a temporary input element
          const tempInput = document.createElement('input');
          tempInput.setAttribute('readonly', ''); // Prevent virtual keyboard on iOS
          tempInput.value = data.deeplink;
          tempInput.style.position = 'absolute';
          tempInput.style.left = '-9999px'; // Move off-screen
          document.body.appendChild(tempInput);

          try {
            if (navigator.userAgent.match(/ipad|iphone/i)) {
              // iOS specific handling
              tempInput.contentEditable = 'true';
              tempInput.readOnly = false;

              // Select the text
              const range = document.createRange();
              range.selectNodeContents(tempInput);
              const selection = window.getSelection();
              selection?.removeAllRanges();
              selection?.addRange(range);
              tempInput.setSelectionRange(0, 999999);
            } else {
              // All other browsers
              tempInput.select();
            }

            document.execCommand('copy');
            showToast({
              message:
                'Checkout link generated successfully and copied to clipboard',
              status: EToasterStatus.SUCCESS
            });
          } catch (err) {
            console.error('Copy failed:', err);
            showToast({
              message:
                'Checkout link generated successfully. Please copy it manually: ' +
                data.deeplink,
              status: EToasterStatus.ERROR
            });
          } finally {
            document.body.removeChild(tempInput);
          }
        },
        onError: (error: any) => {
          console.log('error', error);
          showToast({
            message: 'Something went wrong generating the checkout link',
            status: EToasterStatus.ERROR
          });
        }
      }
    );
  };

  const handleToggleActiveStatus = async (offerId: string, active: boolean) => {
    updateOffer.mutate(
      { offerId, form: { active: !active } },
      {
        onSuccess: () => {
          showToast({
            message: active
              ? 'Checkout link deactivated successfully'
              : 'Checkout link activated successfully',
            status: EToasterStatus.SUCCESS
          });
          getOffers.refetch();
        },
        onError: () => {
          showToast({
            message: 'Error editing checkout link',
            status: EToasterStatus.ERROR
          });
        }
      }
    );
  };

  return (
    <div className="h-screen">
      <PortalHeader
        title="Checkout links"
        primaryButtonText="Create New Link"
        isSearchVisible={hasOffers}
        onPrimaryButtonClick={() => navigate('./form')}
        isCreateButton
      />
      <div className="flex px-9 gap-3">
        <FilterSearchCard
          header="External ID"
          filterValue={skuFilterValue}
          setFilterValue={setSkuFilterValue}
        />
        <FilterDropdownCard
          header="Filter By Status"
          filterValue={statusFilterValue}
          setFilterValue={setStatusFilterValue}
          options={statusFilterOptions}
        />
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-3/4">
          <AnimatedLoadingLogo />
        </div>
      ) : isEmpty ? (
        <EmptyState
          title="Add your first Checkout link"
          description={
            <>
              Checkout links simplify purchases: one tap, auto-login, <br />
              preselected offers and amounts.
            </>
          }
          buttonText="Create New Checkout link"
          className="mx-auto mt-36"
          onClick={() => navigate('./form')}
        />
      ) : (
        <div className="p-9">
          <div className="border border-base-sidebarBorder rounded-[20px]">
            <DataTable data={filteredData} columns={columns} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckoutLinksTable;
