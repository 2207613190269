import React, { FC } from 'react';

import { FontAwesomeIcon } from '@appcharge/shared-ui';

import Button from '../../Button/Button';
import Input from '../../Input/Input';
import {
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '../../ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';

import MediaContent from './MediaContent';
import UploadContent from './UploadContent';

enum EImageUploadModalTabs {
  UPLOAD = 'Upload',
  MEDIA = 'Media'
}

type ImageUploadModalProps = {};

const IMAGE_UPLOAD_TABS = [
  EImageUploadModalTabs.UPLOAD,
  EImageUploadModalTabs.MEDIA
];

const ImageUploadModal: FC<ImageUploadModalProps> = ({}) => {
  return (
    <DialogContent className="bg-base-card overflow-hidden p-0 max-w-[646px] max-h-[680px] h-full w-full gap-0">
      <DialogHeader className="bg-base-muted py-4 px-6 flex flex-row items-center justify-between">
        <DialogTitle className="text-base-foreground text-p-l font-semibold">
          Upload Image
        </DialogTitle>
        <DialogClose asChild>
          <FontAwesomeIcon
            icon="fa-regular fa-xmark"
            className="!mt-0 text-base-secondaryForeground cursor-pointer"
          />
        </DialogClose>
      </DialogHeader>

      <Tabs
        defaultValue={EImageUploadModalTabs.UPLOAD.toLowerCase()}
        className="w-full mt-2.5 px-6 pt-6 h-[520px]"
      >
        <div className="flex items-center">
          <TabsList className="grid grid-cols-2 w-[318px] mb-4 bg-base-muted h-10">
            {IMAGE_UPLOAD_TABS.map((tab, i) => (
              <TabsTrigger
                value={tab.toLowerCase()}
                className="text-p-s py-1.5 font-normal text-base-mutedForeground data-[state=active]:shadow-sm data-[state=active]:bg-base-background data-[state=active]:text-base-foreground"
              >
                {tab}
              </TabsTrigger>
            ))}
          </TabsList>
          <TabsContent
            value={EImageUploadModalTabs.MEDIA.toLowerCase()}
            className="mt-0 mb-4 ml-auto"
          >
            <Input
              rightIcon={
                <FontAwesomeIcon icon="fa-regular fa-magnifying-glass" />
              }
              placeholder="Search"
              className="h-10"
              isHorizontal
            />
          </TabsContent>
        </div>

        <TabsContent
          value={EImageUploadModalTabs.UPLOAD.toLowerCase()}
          className="mt-0"
        >
          <UploadContent />
        </TabsContent>

        <TabsContent
          value={EImageUploadModalTabs.MEDIA.toLowerCase()}
          className="mt-0"
        >
          <MediaContent />
        </TabsContent>
      </Tabs>

      <DialogFooter className="bg-base-muted p-6">
        <DialogClose asChild>
          <Button type="button" variant="link">
            Cancel
          </Button>
        </DialogClose>
        <Button type="submit" disabled={true}>
          Save
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};

export default ImageUploadModal;
